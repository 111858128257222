import '../css/app.css';
import './bootstrap.js';

import * as Sentry from '@sentry/vue';
import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import HandlesTranslations from './Concerns/HandlesTranslations';

/**
 * Create and configure the Inertia Vue application.
 */

createInertiaApp({
    resolve: name => resolvePageComponent(
        `./Views/${name}.vue`,
        import.meta.glob('./Views/**/*.vue')
    ),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) });

        Sentry.init({
            app: app,
            dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
            integrations: [
                new Sentry.Replay({
                    networkDetailAllowUrls: [
                        window.location.origin,
                    ]
                }),
            ],
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
            tracesSampleRate: 1.0,
            tracePropagationTargets: [
                /^ap2\.alldressed\.io/,
            ],
        });

        app.use(plugin)
            .use(HandlesTranslations)
            .use(ZiggyVue, Ziggy)
            .mount(el);
    },
    progress: {
        includeCSS: false,
    },
});
